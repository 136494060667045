.irri-login {
  height: 100vh;
  .login-select {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 70px);
    position: relative;
    .login {
      width: 470px;
      margin: 0 auto;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 2px solid #156366;
      border-radius: 10px;
      padding: 15px;

      .map-logo {
        label {
          width: 415px;
          height: 320px;
          margin: 0 auto;
          display: block;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .irri-logo {
        display: flex;
        margin: 10px 15px;
        justify-content: space-between;
        align-items: center;
        label {
          width: 265px;
          height: 85px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .go-logo {
          width: 100px;
          height: 100px;
        }
      }
      .login-field {
        display: flex;
        flex-direction: column;
        .MuiFormControl-root {
          margin-top: 10px;
        }
        .MuiOutlinedInput-root {
          margin-bottom: 0px;
        }
        .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
          border-color: #116366 !important;
        }
        .MuiFormLabel-root.Mui-focused {
          color: #116366 !important;
        }
        .outlinedInputFocused {
          color: #116366 !important;
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: #116366 !important;
        }
        .MuiSvgIcon-root {
          fill: #116366;
        }
        .MuiButton-contained {
          width: 125px;
          margin: 0 auto;
          margin-top: 15px;
          background-color: transparent !important;
          border: 1px solid #156366 !important;
        }
        .MuiButton-label {
          text-transform: capitalize;
        }
        .MuiFormHelperText-root.Mui-error {
          text-transform: inherit;
        }
      }
      .forgot {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        margin: 10px 0px 20px;
        color: blue;
        font-size: 16px;
      }
    }
    .cloader {
      height: 100%;
      .loader-sec {
        height: calc(100vh - 70px);
      }
    }
  }
}
