.pdf-modal {
  .pdf-header-title {
    display: flex;
    width: 100%;
    .download-pdf {
      .MuiButton-contained {
        width: 110px;
      }
    }
  }
  .modal-dialog {
    max-width: 595px !important;
    .modal-content {
      width: 100% !important;
      height: 90% !important;
      .modal-body {
        overflow: auto;
        height: calc(100vh - 127px);
        .map-pdf-sections {
          padding: 20px;
          .pdf-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            .header-img {
              width: 150px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            h2 {
              font-size: 30px;
              text-align: center;
              color: #116366;
            }
          }
          .selection-list {
            display: flex;
            background-color: #ffeb3b7d;
            flex-wrap: wrap;
            text-align: center;
            margin-bottom: 20px !important;
            padding: 10px 20px;
            border-radius: 8px;
            li {
              padding-right: 20px;
              label {
                font-size: 16px;
                font-weight: 600;
                margin-right: 10px;
              }
              span {
                font-size: 16px;
              }
            }
          }
          .map-image {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            // position: relative;
            .screen-shot {
              img {
                width: 100%;
                height: 100%;
              }
            }
            .north-icon {
              position: absolute;
              left: 21px;
              padding: 5px 3px;
              label {
                width: 30px;
                height: 30px;
                img {
                  object-fit: contain;
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .layer-text {
              font-size: 18px;
              font-weight: 600;
              // position: absolute;
              // bottom: 0px;
              // right: 14px;
            }
          }
        }
      }
    }
  }
}

.view-report {
  .modal-dialog {
    .modal-content {
      width: 230px !important;
      .generate-pdf {
        text-align: center;
        padding: 20px !important;
        label {
          font-size: 24px;
          margin-bottom: 20px !important;
          color: #116366;
        }
        .select-button {
          display: flex;
          justify-content: space-around;
          // width: 50%;
          margin: 0 auto;
        }
      }
    }
  }
}
.modal-content {
  width: 470px;
  margin: 0 auto;
  border-radius: 10px;
  .modal-body {
    padding: 0px;
    .close-icon {
      position: absolute;
      right: -15px;
      top: -20px;
      cursor: pointer;
    }
  }
}
.irri-otp {
  .otp {
    border: 2px solid #156366;
    border-radius: 10px;
    text-align: center;
    padding: 30px;
    h2 {
      font-size: 32px;
      margin: 0px;
      font-weight: 400;
      margin-bottom: 30px !important;
    }
    .otp-field {
      display: flex;
      flex-direction: column;
      .MuiOutlinedInput-root {
        margin-bottom: 20px;
      }
      .MuiFormLabel-root.Mui-focused {
        color: #008000 !important;
      }
      .outlinedInputFocused {
        color: #008000 !important;
      }
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #008000 !important;
      }
      .MuiButton-contained {
        background-color: transparent !important;
        border: 1px solid #156366 !important;
      }
      .MuiButton-label {
        text-transform: capitalize;
      }
      .react-tel-input {
        margin-bottom: 20px;
        .form-control {
          width: 100%;
          padding: 27px 14px 27px 58px;
          &:focus {
            border-color: #008000;
            box-shadow: 0 0 0 1px #008000;
          }
        }
        .special-label {
          color: #008000;
        }
        .country-list {
          .country {
            text-align: start;
          }
        }
      }
    }
    .otp-sections {
      margin-bottom: 30px;
      input {
        width: 4.6em !important;
        border: 1px solid #c4c4c4;
        border-radius: 4px;
        padding: 15px;
        &:focus {
          border: 2px solid #008000;
          outline: transparent;
        }
      }
      span {
        margin: 0px 15px;
        font-weight: 800;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .resend-otp {
    display: flex;
    justify-content: space-evenly;
  }
}
