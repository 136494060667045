.select-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  .MuiInput-root {
    padding: 0px 14px;
  }
   .MuiFormControl-root {
    width: 100%;
    margin: 3px 0px 0px;
  }
  .MuiOutlinedInput-input {
    padding: 15px 14px;
    background-color: #ffffff;
    color: #262261;
  }
  .MuiSelect-select:focus {
    background-color: #dcdaf1;
  }
  .MuiInput-underline:before {
    border: 1px solid rgba(0, 0, 0, 0.42);
    padding: 6px;
    border-radius: 4px;
  }
  .MuiSelect-icon {
    color: #116366;
  }
  .MuiInput-underline::after {
    border-radius: 4px;
    padding: 15px;
    border: 2px solid #3f51b5;
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #116366 !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #116366 !important;
  }
  .outlinedInputFocused {
    color: #116366 !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #116366 !important;
  }
  .MuiInputBase-root.Mui-disabled .MuiSelect-outlined.MuiSelect-outlined {
    background-color: #e7eff0;
  }
  .MuiInputLabel-outlined {
    color: #116366;
  }
}
.MuiMenu-paper .MuiList-root.MuiMenu-list.MuiList-padding .MuiListItem-button{
  color: #262261 !important;
}