.irri-admin-sign-up {
  border: 2px solid #156366;
  border-radius: 10px;
  text-align: center;
  width: 470px;
  padding: 30px;
  .sign-up {
    h2 {
      font-size: 32px;
      margin: 0px;
      font-weight: 400;
      margin-bottom: 10px !important;
    }

    .sign-up-field {
      display: flex;
      flex-direction: column;
      .MuiFormControl-root {
        margin-top: 20px;
      }
      .MuiFormHelperText-root {
        font-size: 11px;
        font-weight: 600;
        // color: #000;
        // text-align: center;
      }
      .MuiFormLabel-root.Mui-focused {
        color: #008000 !important;
        padding: 5px;
        background: #e8d6d6;
      }
      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        padding: 5px;
        background: #e8d6d6;
      }
      .outlinedInputFocused {
        color: #008000 !important;
      }
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #008000 !important;
      }
      .MuiButton-contained {
        background-color: transparent !important;
        border: 1px solid #156366 !important;
        width: 100px;
        margin:0 auto;
        margin-top: 20px;
      }
      .MuiButton-label {
        text-transform: capitalize;
      }
      // .password-set {
      //   font-size: 14px;
      //   color: #212529;
      //   padding-bottom: 3px;
      // }
      .react-tel-input {
        margin-top: 20px;
        .form-control {
          width: 100%;
          padding: 27px 14px 27px 58px;
          background: transparent;
          border: 1px solid #7f653d;
          &:focus {
            border-color: #008000;
            box-shadow: 0 0 0 1px #008000;
          }
        }
        .special-label {
          color: #008000;
          background: #e8d6d6;
        }
        .country-list {
          .country {
            text-align: start;
          }
        }
      }
    }
  }
}
