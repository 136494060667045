.header-wrapper {
  height: 70px;
  background-color: #e7eff0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 2px dashed #272b61;
  ul {
    display: flex;
    align-items: center;
    flex-basis: 18%;
  }
  .left-side {
    li {
      width: 50px;
      height: 50px;
      &:not(:last-child) {
        margin-right: 50px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .header-heading {
    font-size: 28px;
    line-height: 32px;
    color: #116366;
    @media only screen and (max-width: 1300px) {
      font-size: 19px;
      text-align: center;
    }
  }
  .flogo-icom {
    flex-basis: 18%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .homeLogo {
      width: 80px;
      height: 80px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .sarLogo {
      width: 140px;
      height: 50px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .right-side {
    justify-content: flex-end;
    li {
      &:not(:last-child) {
        margin-right: 20px;
        padding: 5px;
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid #000;
        cursor: pointer;
        button {
          background-color: transparent;
          border: none;
          padding: 0px;
        }
      }
      label {
        display: block;
        width: 30px;
        height: 30px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .active {
      background-color: #8bbfc1 !important;
      cursor: pointer;
      label {
        cursor: pointer;
      }
    }
    .logout-sections {
      .MuiSvgIcon-root {
        width: 40px;
        height: 40px;
        fill: #116366;
      }
    }
  }
}
