.irri-forgot {
  .forgot-password {
    border: 2px solid #156366;
    border-radius: 10px;
    text-align: center;
    width: 470px;
    padding: 30px;
    h2 {
      font-size: 32px;
      margin: 0px;
      font-weight: 400;
      margin-bottom: 10px !important;
    }
    .forgot-field {
      display: flex;
      flex-direction: column;
      .MuiFormControl-root {
        margin-top: 20px;
      }
      .MuiFormLabel-root.Mui-focused {
        color: #008000 !important;
        padding: 5px;
        background: #e8d6d6;
      }
      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        padding: 5px;
        background: #e8d6d6;
      }
      .outlinedInputFocused {
        color: #008000 !important;
      }
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #008000 !important;
      }
      .MuiButton-contained {
        background-color: transparent !important;
        border: 1px solid #156366 !important;
        margin: 0 auto;
        margin-top: 20px;
      }
      .MuiButton-label {
        text-transform: capitalize;
      }
    }
  }
}
