body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: transparent !important;
  background-image: none !important;
  color: -internal-light-dark(black, white) !important;
}

@font-face {
  font-family: "sf-regular";
  src: url("./asstes/fonts/sf-pro-regular.ttf") format("opentype");
}

@font-face {
  font-family: "sf-bold";
  src: url("./asstes/fonts/sf-pro-bold.ttf") format("opentype");
}

@font-face {
  font-family: "sf-medium";
  src: url("./asstes/fonts/sf-pro-medium.ttf") format("opentype");
}
