.dashboard-sections {
  height: 100vh;
  position: relative;
  .dashboard-components {
    display: flex;
    position: relative;
    .dashboard-section {
      z-index: 999;
    }
  }
}
.map-filter {
  display: flex;
  flex-direction: column;
}
