.leaflet-control-attribution a {
  // display: none;
}
.leaflet-control-attribution.leaflet-control {
  display: none;
}
.leaflet-top.leaflet-right
  .leaflet-control-layers:nth-child(2)
.leaflet-control-layers-base {
  label:nth-child(2) {
    display: none !important;
  }
}
.map-sections {
  width: 100%;
  position: relative;
  .state-layer {
    font-size: 20px;
    color: #ffffff;
  }
  .bar-compare {
    position: absolute;
    right: 0;
    top: 80px;
    z-index: 9999;
    cursor: pointer;
    ul {
      li {
        background-color: #ffffff;
        border-radius: 8px;
        padding: 9px;
        margin-top: 10px;
        margin-right: 10px;
        label {
          cursor: pointer;
          display: block;
          width: 30px;
          height: 30px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  // .map-container{
  //   height: 100vh;
  //   width: 100%;
  // }
  // .openlayers-map {
  //   height: 100vh !important;
  //   width: 100%;
  //   .ol-zoom{
  //     right: 0px;
  //     left: auto;
  //    .ol-zoom-in {
  //     border-radius: 2px 2px 0 0;
  //     margin-bottom: 5px;
  // }
  .leaflet-container {
    width: 100%;
    height: calc(100vh - 70px);
    .legends-sec {
      position: absolute;
      bottom: 85px;
      padding: 5px;
      background-color: #ffffff;
      z-index: 99999;
      left: 6.4px;
      border: 2px solid #156366 !important;
      .legend-list {
        display: flex;
        flex-direction: column-reverse;
        .flex-item {
          display: flex;
          &:not(:first-child) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
