.sidebar-sections {
  display: flex;
  // position: relative;
  .arrow-animations {
    position: absolute;
    cursor: pointer;
    top: 0px;
    left: 290px;
    z-index: 999;
    transition: all 0.75s ease;
    .sidebar-arrow {
      background-color: #eeefeee6;
      padding: 6px;
      border-radius: 5px;
      display: inline-block;
      label {
        transition: all 0.75s ease;
        transform: rotate(180deg);
        cursor: pointer;
        width: 25px;
        height: 25px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .arrow-animations.active {
    left: 0px;
    transition: all 0.75s ease;
  }
  .arrow-animations.active .sidebar-arrow label {
    transform: rotate(0deg);
    transition: all 0.75s ease;
  }
  .hide {
    display: none !important;
  }
  .side-left {
    background-color: #dbded9e6;
    width: 70px;
    height: 100vh;
    border-right: 2px dashed #272b61;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .header-sections {
      margin-top: 20px;
      .header-logo {
        width: 50px;
        height: 50px;
        margin: 0 auto;
        margin-bottom: 30px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      ul {
        li {
          label {
            width: 30px;
            height: 30px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
          span {
            cursor: pointer;
            display: block;
            font-size: 18px;
            line-height: 20px;
          }
          &:not(:last-child) {
            margin-bottom: 25px;
          }
        }
      }
    }
    .logout-sections {
      margin-bottom: 10px;
    }
  }

  //SideBar Right
  .side-right {
    display: flex;
    flex-direction: column;
    align-content: space-around;
    justify-content: space-evenly;
    background-color: #8bbfc1;
    width: 290px;
    overflow: hidden;
    height: calc(100vh - 70px);
    border-right: 2px dashed #272b61;
    transition: all 0.75s ease;
    margin-left: 0px;
    ul {
      padding: 15px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-bottom: 0px;
      li {
        margin-bottom: 10px;
      }
    }
    .conform-button {
      margin: 0px 20px;
      text-align: center;
      .fun-button {
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;
        .button {
          width: auto;
          text-align: center;
        }
        .export-button {
          .ex-btn {
            font-size: 18px;
            font-family: "sf-regular" !important;
            line-height: 18px;
            border-radius: 4px;
            color: #000;
            border: 0px;
            background: #e0e0e0;
            &:focus {
              box-shadow: none;
            }
          }
        }
        .succ {
          opacity: 0;
        }
      }
    }
  }
  .compare-bar-display {
    background-color: #8bbfc1;
    width: 290px;
    overflow: hidden;
    height: calc(100vh - 70px);
    border-right: 2px dashed #272b61;
    transition: all 0.75s ease;
    margin-left: 0px;
    ul {
      padding: 20px;
      li {
        margin-bottom: 15px;
        .list-product {
          border: 1px solid #aaaaaa;
          width: 100%;
          margin: 3px 0px 0px;
          display: block;
          padding: 10px;
          border-radius: 8px;
        }
      }

      .season-list {
        display: flex;
        justify-content: space-between;
      }
    }
    .export-bar-button {
      text-align: center;
    }
    .apply-clear {
      display: flex;
      justify-content: space-around;
    }

    .selectpicker-arrow {
      .MuiSelect-iconOutlined {
        display: none;
      }
    }
  }
  .active {
    // transition: all 0.5s;
    transition: all 0.75s ease;
    //  display: none;
    // margin-left: -281px;
    // opacity: 0;
    width: 0px;
    border-right: 0px solid;
  }
}
// }
