body {
  margin: 0px;
  background: #ffffff;
  font-family: "sf-regular" !important;
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  margin-bottom: 0px !important;
}

h1,
h2,
p,
label,
span {
  margin: 0px;
  padding: 0px;
  margin-bottom: 0px !important;
}

a {
  cursor: pointer;
  color: #000 !important;
  text-decoration: none;
}
a:hover {
  text-decoration: none !important;
  color: #000 !important;
}
.leaflet-control-simpleMapScreenshoter-btn {
  display: none !important;
}

//Types Map View
#fade-menu {
  .MuiList-root {
    .MuiButtonBase-root {
      .map-img {
        width: 25px;
        height: 25px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .map-text {
        margin-left: 10px;
      }
    }
  }
}
.leaflet-control-scale-line {
  background: #fff !important;
  color: #000 !important;
  font-weight: 600 !important;
  padding: 5px !important;
  font-size: 14px !important;
  border: 2px solid #156366 !important;
}

// Component Loader
.cloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 99999;
}
.leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom
  .leaflet-control-container
  .leaflet-top.leaflet-left {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .leaflet-control-loading.is-loading {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 60px;
    height: 60px;
    background-color: transparent !important;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
  // .leaflet-control-loading.is-loading:before {
  //   width: 50%;
  //   height: 50%;
  //   background: #ffffff;
  //   border-radius: 100% 0 0 0;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   content: "";
  // }

  // .leaflet-control-loading.is-loading:after {
  //   background: transparent;
  //   width: 75%;
  //   height: 75%;
  //   border-radius: 50%;
  //   content: "";
  //   margin: auto;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   right: 0;
  // }
  // @-webkit-keyframes load3 {
  //   0% {
  //     -webkit-transform: rotate(0deg);
  //     transform: rotate(0deg);
  //   }
  //   100% {
  //     -webkit-transform: rotate(360deg);
  //     transform: rotate(360deg);
  //   }
  // }
  // @keyframes load3 {
  //   0% {
  //     -webkit-transform: rotate(0deg);
  //     transform: rotate(0deg);
  //   }
  //   100% {
  //     -webkit-transform: rotate(360deg);
  //     transform: rotate(360deg);
  //   }
  // }
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none !important;
}
.leaflet-top.leaflet-left
  .leaflet-control-zoom.leaflet-control-layer-container.leaflet-bar.leaflet-control.is-loading {
  background-color: rgba(255, 255, 255, 0.5);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}
