.admin-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 470px;
  margin: 0 auto;
  .admin-wrapper {
    border: 2px solid #156366;
    border-radius: 10px;
    padding: 30px 0px;
    h2 {
      font-size: 32px;
      margin: 0px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 30px !important;
      padding-bottom: 30px;
      border-bottom: 2px solid #156366;
    }
    .common-text {
      font-size: 16px;
      margin: 0px;
      cursor: pointer;
      font-weight: 400;
      text-align: center;
      color: #156366;
      border: 2px solid #156366;
      border-radius: 7px;
      padding: 5px;
      width: 200px;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    .admin-list {
      text-align: center;
      margin-bottom: 25px;
      .margin-bottom {
        margin-bottom: 25px;
      }
    }
    .admin-logout {
      display: flex;
      justify-content: flex-end;
      .common-text {
        width: 100px;
        margin-right: 20px;
      }
    }
  }
}
