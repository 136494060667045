$width: 15px;
$height: 15px;
$bounce_height: 15px;

.text {
  color: #272b61;
  display: inline-block;
  margin-left: 5px;
  font-family: "sf-regular" !important;
  font-size: 12px;
  font-weight: bold;
}
p {
  text-transform: capitalize;
}
.bounceball {
  position: relative;
  display: inline-block;
  height: 17px;
  width: $width;
  &:before {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    width: $width;
    height: $height;
    border-radius: 50%;
    background-color: #272b61;
    transform-origin: 50%;
    animation: bounce 500ms alternate infinite ease;
  }
}

.loader-button {
  width: 80px;
  .MuiButton-label {
    p {
      font-size: 18px;
      font-family: "sf-regular" !important;
      line-height: 18px;
      margin: 0px;
      color: #116366;
    }
  }
}

@keyframes bounce {
  0% {
    top: $bounce_height;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: $height;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}

.loadbtn {
  display: flex;
  height: 20px;
}
.loadbtncenter {
  position: absolute;
  top: 16px;
  left: 40%;
  right: 50%;
  transform: translate(-50%);
}
.loader {
  display: flex;
  align-items: center;
}
.circle {
  width: 10px;
  height: 10px;
  background: #2d3f88;
  border-radius: 50%;
  animation: jump 1s linear infinite;
  margin: 0 5px;
}
.caption {
  margin: auto;
  font-family: arial;
  font-size: 20px;
  color: white;
}
#b {
  animation-delay: 0.2s;
}
#c {
  animation-delay: 0.4s;
}
@keyframes jump {
  0% {
    margin-top: 0;
  }
  35% {
    margin-top: -10px;
  }
  70% {
    margin-top: 0px;
  }
}
