.chart-sections {
  .barchart-list {
    display: flex;
    justify-content: space-between;
    margin: 10px !important;
    .filter-list {
      display: flex;
      li {
        &:not(:last-child) {
          margin-right: 20px;
        }
        span {
          font-size: 22px;
          line-height: 18px;
        }
        label {
          font-size: 16px;
          line-height: 18px;
          color: blue;
        }
      }
    }
  }
  .map-image-pdf {
    margin-bottom: 40px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .bar-chart {
    .chartjs-render-monitor {
      width: calc(100vw - 290px) !important;
      height: calc(100vh - 100px) !important;
      margin: 15px 0px;
    }
  }
}
