.loader-sec {
  height: 100%;
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999999;
  text-align: center;
  width: 100%;
}
.load {
  position: absolute;
  top: 50%;  
  left: 50%; 
  transform: translate(-50%, -50%);
  z-index: 999;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 60px;
  height: 60px;
  background-color: transparent !important;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
